.table-container {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-header {
    background-color: #4CAF50;
    color: white;
  }
  
  .table-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 8px;
  }
  
  .table-cell {
    flex: 1;
    text-align: left;
    padding: 8px;
    cursor: pointer;
  }
  
  .table-cell:hover {
    background-color: #f1f1f1;
  }
  
  .expanded-row {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: #f9f9f9;
  }
  
  .expanded-cell {
    flex: 1;
    padding: 8px;
  }
  
  .expanded-cell label {
    display: block;
    margin-bottom: 8px;
  }
  
  .expanded-cell input {
    width: 100%;
    padding: 6px;
    margin: 4px 0;
    box-sizing: border-box;
  }
  
  .expanded-cell button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .expanded-cell button:hover {
    background-color: #45a049;
  }
  